import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumberPaste]',
})
export class OnlyNumberPasteDirective {
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData?.getData('text');
    if (pastedText && isNaN(Number(pastedText))) {
      event.preventDefault();
      alert('Please paste a valid number');
    }
  }
}
