/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthorWithRelations } from '../models/author-with-relations';
import { ContactRole } from '../models/contact-role';
import { ContactWithRelations } from '../models/contact-with-relations';
import { Inventory } from '../models/inventory';
import { InventoryPartial } from '../models/inventory-partial';
import { InventoryRole } from '../models/inventory-role';
import { InventoryUser } from '../models/inventory-user';
import { InventoryWithRelations } from '../models/inventory-with-relations';
import { LocationWithRelations } from '../models/location-with-relations';
import { NewInventory } from '../models/new-inventory';
import { Tag } from '../models/tag';
import { TagGroupWithRelations } from '../models/tag-group-with-relations';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class InventoryControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation inventoryControllerGetInventoryRoles
   */
  static readonly InventoryControllerGetInventoryRolesPath = '/admin/inventories/roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryRoles$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<InventoryRole>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerGetInventoryRolesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InventoryRole>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInventoryRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryRoles(params?: {
    context?: HttpContext
  }
): Observable<Array<InventoryRole>> {

    return this.getInventoryRoles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InventoryRole>>) => r.body as Array<InventoryRole>)
    );
  }

  /**
   * Path part for operation inventoryControllerChangeInventoryUserRole
   */
  static readonly InventoryControllerChangeInventoryUserRolePath = '/admin/inventories/user/{id}/change';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeInventoryUserRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeInventoryUserRole$Response(params: {
    id: string;
    roleId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<InventoryUser>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerChangeInventoryUserRolePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('roleId', params.roleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InventoryUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeInventoryUserRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeInventoryUserRole(params: {
    id: string;
    roleId: number;
    context?: HttpContext
  }
): Observable<InventoryUser> {

    return this.changeInventoryUserRole$Response(params).pipe(
      map((r: StrictHttpResponse<InventoryUser>) => r.body as InventoryUser)
    );
  }

  /**
   * Path part for operation inventoryControllerDeleteInventoryUserById
   */
  static readonly InventoryControllerDeleteInventoryUserByIdPath = '/admin/inventories/user/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteInventoryUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteInventoryUserById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerDeleteInventoryUserByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteInventoryUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteInventoryUserById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.deleteInventoryUserById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation inventoryControllerGetInventoryLocations
   */
  static readonly InventoryControllerGetInventoryLocationsPath = '/admin/inventories/{id}/locations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryLocations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryLocations$Response(params: {
    id: string;
    nested?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<LocationWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerGetInventoryLocationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('nested', params.nested, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocationWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInventoryLocations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryLocations(params: {
    id: string;
    nested?: boolean;
    context?: HttpContext
  }
): Observable<Array<LocationWithRelations>> {

    return this.getInventoryLocations$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocationWithRelations>>) => r.body as Array<LocationWithRelations>)
    );
  }

  /**
   * Path part for operation inventoryControllerGetInventoryTagsFlat
   */
  static readonly InventoryControllerGetInventoryTagsFlatPath = '/admin/inventories/{id}/tags/flat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryTagsFlat()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryTagsFlat$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<Tag>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerGetInventoryTagsFlatPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Tag>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInventoryTagsFlat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryTagsFlat(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<Tag>> {

    return this.getInventoryTagsFlat$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Tag>>) => r.body as Array<Tag>)
    );
  }

  /**
   * Path part for operation inventoryControllerGetInventoryTags
   */
  static readonly InventoryControllerGetInventoryTagsPath = '/admin/inventories/{id}/tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryTags$Response(params: {
    id: string;
    includeTags?: boolean;
    nested?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<TagGroupWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerGetInventoryTagsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('includeTags', params.includeTags, {});
      rb.query('nested', params.nested, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TagGroupWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInventoryTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryTags(params: {
    id: string;
    includeTags?: boolean;
    nested?: boolean;
    context?: HttpContext
  }
): Observable<Array<TagGroupWithRelations>> {

    return this.getInventoryTags$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TagGroupWithRelations>>) => r.body as Array<TagGroupWithRelations>)
    );
  }

  /**
   * Path part for operation inventoryControllerGetInventoryUsers
   */
  static readonly InventoryControllerGetInventoryUsersPath = '/admin/inventories/{id}/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryUsers$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<InventoryUser>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerGetInventoryUsersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InventoryUser>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInventoryUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryUsers(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<InventoryUser>> {

    return this.getInventoryUsers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InventoryUser>>) => r.body as Array<InventoryUser>)
    );
  }

  /**
   * Path part for operation inventoryControllerAddInventoryUser
   */
  static readonly InventoryControllerAddInventoryUserPath = '/admin/inventories/{id}/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addInventoryUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  addInventoryUser$Response(params: {
    id: string;
    userId: string;
    roleId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<InventoryUser>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerAddInventoryUserPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('userId', params.userId, {});
      rb.query('roleId', params.roleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InventoryUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addInventoryUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addInventoryUser(params: {
    id: string;
    userId: string;
    roleId: number;
    context?: HttpContext
  }
): Observable<InventoryUser> {

    return this.addInventoryUser$Response(params).pipe(
      map((r: StrictHttpResponse<InventoryUser>) => r.body as InventoryUser)
    );
  }

  /**
   * Path part for operation inventoryControllerGetInventoryAuthors
   */
  static readonly InventoryControllerGetInventoryAuthorsPath = '/inventories/authors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryAuthors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryAuthors$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AuthorWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerGetInventoryAuthorsPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AuthorWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInventoryAuthors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryAuthors(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<AuthorWithRelations>> {

    return this.getInventoryAuthors$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AuthorWithRelations>>) => r.body as Array<AuthorWithRelations>)
    );
  }

  /**
   * Path part for operation inventoryControllerGetInventoryContactRoles
   */
  static readonly InventoryControllerGetInventoryContactRolesPath = '/inventories/contact-roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryContactRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryContactRoles$Response(params: {
    orderType: string;
    inventoryId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ContactRole>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerGetInventoryContactRolesPath, 'get');
    if (params) {
      rb.query('orderType', params.orderType, {});
      rb.query('inventoryId', params.inventoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContactRole>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInventoryContactRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryContactRoles(params: {
    orderType: string;
    inventoryId: string;
    context?: HttpContext
  }
): Observable<Array<ContactRole>> {

    return this.getInventoryContactRoles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ContactRole>>) => r.body as Array<ContactRole>)
    );
  }

  /**
   * Path part for operation inventoryControllerGetInventoryContacts
   */
  static readonly InventoryControllerGetInventoryContactsPath = '/inventories/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryContacts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryContacts$Response(params: {
    inventoryId: string;
    roleCodes?: Array<string>;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ContactWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerGetInventoryContactsPath, 'get');
    if (params) {
      rb.query('inventoryId', params.inventoryId, {});
      rb.query('roleCodes', params.roleCodes, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContactWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInventoryContacts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryContacts(params: {
    inventoryId: string;
    roleCodes?: Array<string>;
    context?: HttpContext
  }
): Observable<Array<ContactWithRelations>> {

    return this.getInventoryContacts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ContactWithRelations>>) => r.body as Array<ContactWithRelations>)
    );
  }

  /**
   * Path part for operation inventoryControllerCount
   */
  static readonly InventoryControllerCountPath = '/inventories/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
    context?: HttpContext
  }
): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation inventoryControllerGetInventoryWithRelations
   */
  static readonly InventoryControllerGetInventoryWithRelationsPath = '/inventories/{id}/complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryWithRelations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryWithRelations$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<InventoryWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerGetInventoryWithRelationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InventoryWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInventoryWithRelations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryWithRelations(params: {
    id: string;
    context?: HttpContext
  }
): Observable<InventoryWithRelations> {

    return this.getInventoryWithRelations$Response(params).pipe(
      map((r: StrictHttpResponse<InventoryWithRelations>) => r.body as InventoryWithRelations)
    );
  }

  /**
   * Path part for operation inventoryControllerFindById
   */
  static readonly InventoryControllerFindByIdPath = '/inventories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<InventoryWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InventoryWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
    context?: HttpContext
  }
): Observable<InventoryWithRelations> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<InventoryWithRelations>) => r.body as InventoryWithRelations)
    );
  }

  /**
   * Path part for operation inventoryControllerReplaceById
   */
  static readonly InventoryControllerReplaceByIdPath = '/inventories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById$Response(params: {
    id: string;
    context?: HttpContext
    body?: Inventory
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerReplaceByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `replaceById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById(params: {
    id: string;
    context?: HttpContext
    body?: Inventory
  }
): Observable<any> {

    return this.replaceById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation inventoryControllerDeleteById
   */
  static readonly InventoryControllerDeleteByIdPath = '/inventories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation inventoryControllerUpdateById
   */
  static readonly InventoryControllerUpdateByIdPath = '/inventories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    context?: HttpContext
    body?: InventoryPartial
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    context?: HttpContext
    body?: InventoryPartial
  }
): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation inventoryControllerFind
   */
  static readonly InventoryControllerFindPath = '/inventories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<InventoryWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InventoryWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
    context?: HttpContext
  }
): Observable<Array<InventoryWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InventoryWithRelations>>) => r.body as Array<InventoryWithRelations>)
    );
  }

  /**
   * Path part for operation inventoryControllerCreate
   */
  static readonly InventoryControllerCreatePath = '/inventories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    context?: HttpContext
    body?: NewInventory
  }
): Observable<StrictHttpResponse<Inventory>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Inventory>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    context?: HttpContext
    body?: NewInventory
  }
): Observable<Inventory> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<Inventory>) => r.body as Inventory)
    );
  }

  /**
   * Path part for operation inventoryControllerUpdateAll
   */
  static readonly InventoryControllerUpdateAllPath = '/inventories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll$Response(params?: {
    where?: any;
    context?: HttpContext
    body?: InventoryPartial
  }
): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, InventoryControllerService.InventoryControllerUpdateAllPath, 'patch');
    if (params) {
      rb.query('where', params.where, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll(params?: {
    where?: any;
    context?: HttpContext
    body?: InventoryPartial
  }
): Observable<LoopbackCount> {

    return this.updateAll$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

}
