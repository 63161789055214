/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AdminAuctionsControllerService } from './services/admin-auctions-controller.service';
import { AdminAuthorControllerService } from './services/admin-author-controller.service';
import { DocumentTypeControllerService } from './services/document-type-controller.service';
import { AdminExhibitionsControllerService } from './services/admin-exhibitions-controller.service';
import { AdminFilesControllerService } from './services/admin-files-controller.service';
import { AdminI2RelationsControllerService } from './services/admin-i-2-relations-controller.service';
import { AdminControllerService } from './services/admin-controller.service';
import { InventoryControllerService } from './services/inventory-controller.service';
import { AdminInventoryControllerService } from './services/admin-inventory-controller.service';
import { AdminItemsControllerService } from './services/admin-items-controller.service';
import { AdminItemAuctionsControllerService } from './services/admin-item-auctions-controller.service';
import { AdminItemExhibitionsControllerService } from './services/admin-item-exhibitions-controller.service';
import { AdminItemOwnersControllerService } from './services/admin-item-owners-controller.service';
import { AdminItemPublicationsControllerService } from './services/admin-item-publications-controller.service';
import { AdminItemSellTypeControllerService } from './services/admin-item-sell-type-controller.service';
import { LocationTypeControllerService } from './services/location-type-controller.service';
import { LocationControllerService } from './services/location-controller.service';
import { EventLogControllerService } from './services/event-log-controller.service';
import { AdminPublicationsControllerService } from './services/admin-publications-controller.service';
import { AdminSeriesControllerService } from './services/admin-series-controller.service';
import { AdminSeriesTypeControllerService } from './services/admin-series-type-controller.service';
import { TagGroupControllerService } from './services/tag-group-controller.service';
import { TagControllerService } from './services/tag-controller.service';
import { AdminUserControllerService } from './services/admin-user-controller.service';
import { AuctionControllerService } from './services/auction-controller.service';
import { AuthRoleControllerService } from './services/auth-role-controller.service';
import { ContactTypeControllerService } from './services/contact-type-controller.service';
import { CurrencyControllerService } from './services/currency-controller.service';
import { EditorAuthorControllerService } from './services/editor-author-controller.service';
import { EditorContactControllerService } from './services/editor-contact-controller.service';
import { EditorContactRoleControllerService } from './services/editor-contact-role-controller.service';
import { ExhibitionControllerService } from './services/exhibition-controller.service';
import { FileContactControllerService } from './services/file-contact-controller.service';
import { ImportControllerService } from './services/import-controller.service';
import { InventoryItemControllerService } from './services/inventory-item-controller.service';
import { InventoryPublicationControllerService } from './services/inventory-publication-controller.service';
import { InventoryAuthorControllerService } from './services/inventory-author-controller.service';
import { InventoryContactControllerService } from './services/inventory-contact-controller.service';
import { InventoryTypeControllerService } from './services/inventory-type-controller.service';
import { ItemDisputationTypeControllerService } from './services/item-disputation-type-controller.service';
import { ItemTitleControllerService } from './services/item-title-controller.service';
import { ItemTypeControllerService } from './services/item-type-controller.service';
import { ItemAuthorControllerService } from './services/item-author-controller.service';
import { ItemControllerService } from './services/item-controller.service';
import { ItemLocationControllerService } from './services/item-location-controller.service';
import { ItemSignatureControllerService } from './services/item-signature-controller.service';
import { LocationItemControllerService } from './services/location-item-controller.service';
import { OwnerTypeControllerService } from './services/owner-type-controller.service';
import { PublicationTypeControllerService } from './services/publication-type-controller.service';
import { PublicationControllerService } from './services/publication-controller.service';
import { PublicationInventoryControllerService } from './services/publication-inventory-controller.service';
import { SignaturePlaceControllerService } from './services/signature-place-controller.service';
import { SignatureTypeControllerService } from './services/signature-type-controller.service';
import { SignatureControllerService } from './services/signature-controller.service';
import { UserControllerService } from './services/user-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AdminAuctionsControllerService,
    AdminAuthorControllerService,
    DocumentTypeControllerService,
    AdminExhibitionsControllerService,
    AdminFilesControllerService,
    AdminI2RelationsControllerService,
    AdminControllerService,
    InventoryControllerService,
    AdminInventoryControllerService,
    AdminItemsControllerService,
    AdminItemAuctionsControllerService,
    AdminItemExhibitionsControllerService,
    AdminItemOwnersControllerService,
    AdminItemPublicationsControllerService,
    AdminItemSellTypeControllerService,
    LocationTypeControllerService,
    LocationControllerService,
    EventLogControllerService,
    AdminPublicationsControllerService,
    AdminSeriesControllerService,
    AdminSeriesTypeControllerService,
    TagGroupControllerService,
    TagControllerService,
    AdminUserControllerService,
    AuctionControllerService,
    AuthRoleControllerService,
    ContactTypeControllerService,
    CurrencyControllerService,
    EditorAuthorControllerService,
    EditorContactControllerService,
    EditorContactRoleControllerService,
    ExhibitionControllerService,
    FileContactControllerService,
    ImportControllerService,
    InventoryItemControllerService,
    InventoryPublicationControllerService,
    InventoryAuthorControllerService,
    InventoryContactControllerService,
    InventoryTypeControllerService,
    ItemDisputationTypeControllerService,
    ItemTitleControllerService,
    ItemTypeControllerService,
    ItemAuthorControllerService,
    ItemControllerService,
    ItemLocationControllerService,
    ItemSignatureControllerService,
    LocationItemControllerService,
    OwnerTypeControllerService,
    PublicationTypeControllerService,
    PublicationControllerService,
    PublicationInventoryControllerService,
    SignaturePlaceControllerService,
    SignatureTypeControllerService,
    SignatureControllerService,
    UserControllerService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
